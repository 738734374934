import React, { useState } from "react"
import { Helmet } from "react-helmet-async"
import { translations } from "../pages/translations" // Import translations

export default function FAQs({ language = "en" }) {
  const [activeIndex, setActiveIndex] = useState(null)
  const t = translations[language] || translations["en"]

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index)
  }

  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: t.faq.options.map((item) => ({
      "@type": "Question",
      name: item.question,
      acceptedAnswer: {
        "@type": "Answer",
        text: item.answer,
      },
    })),
  }

  return (
    <div className="w-full flex flex-col text-white items-center justify-center text-center mt-32">
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
      </Helmet>
      <h2 className="w-11/12 2xl:w-10/12 mb-4 text-3xl sm:text-4xl font-extrabold text-white">
        {t.faq.title}
      </h2>

      <div className="w-10/12 mt-5 lg:w-9/12 flex flex-col items-center">
        {t.faq.options.map((item, key) => (
          <div key={key} className="relative inline-block text-left w-full">
            <div
              className={`w-full text-l p-3 px-5 flex items-center text-left justify-between border-b cursor-pointer ${
                activeIndex === key ? "bg-neutral-800" : ""
              }`}
              onClick={() => toggleFAQ(key)}
            >
              <div className="w-full">{item.question}</div>
              <div>{activeIndex === key ? "-" : "+"}</div>
            </div>

            {activeIndex === key && (
              <div className="flex flex-col items-center h-full mb-8">
                <div className="w-full p-5 flex items-center justify-between">
                  {item.answer}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
