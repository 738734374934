import React, { useEffect, useState } from "react"
import { useAuth } from "../../context/AuthContext"
import { firestore, serverTimestamp } from "../../firebase"
import { telegram } from "../../utils/telegram"
import { IconInfo } from "../../components/Icons"
import { uploadToR2 } from "../../components/uploadToR2"
import { useHistory } from "react-router-dom"

export default function Upscale({
  setCreateImageFunction,
  creditCost,
  setCreditCost,
}) {
  const history = useHistory()
  const { user } = useAuth()

  const [error, setError] = useState(false)
  const [selectedFile, setSelectedFile] = useState({
    file: null,
    width: 0,
    height: 0,
    fileUrl: "",
  })
  const [creativity, setCreativity] = useState(0)
  const [resemblance, setResemblance] = useState(0)
  const [dynamic, setDynamic] = useState(0)
  const [segmentation, setSegmentation] = useState(0)
  const [multiplier, setMultiplier] = useState(2)
  const [style, setStyle] = useState("default")
  const [prompt, setPrompt] = useState("")
  const [stage, setStage] = useState("live")
  const [isPattern, setIsPattern] = useState(false)

  const [creativityTooltip, setCreativityTooltip] = useState(false)
  const [resemblanceTooltip, setResemblanceTooltip] = useState(false)
  const [dynamicToolTip, setDynamicTooltip] = useState(false)
  const [segmentationTooltip, setSegmentationTooltip] = useState(false)
  const [patternToolTip, setPatternToolTip] = useState(false)
  const [styleTooltip, setStyleTooltip] = useState(false)

  const [finalMegapixel, setFinalMegapixel] = useState()

  const handlePatternChange = (event) => {
    setIsPattern(event.target.checked)
  }

  useEffect(() => {
    if (selectedFile.file) {
      setFinalMegapixel(
        (selectedFile.width * selectedFile.height * multiplier * multiplier) /
          1000000
      )
    }
  }, [selectedFile, multiplier])

  useEffect(() => {
    if (finalMegapixel) {
      let tempCreditCost = 0
      if (finalMegapixel > 178) {
        tempCreditCost = false
      }
      if (multiplier <= 2) {
        if (finalMegapixel <= 6) {
          tempCreditCost = 5
        } else if (finalMegapixel > 6 && finalMegapixel <= 12) {
          tempCreditCost = 10
        } else if (finalMegapixel > 12 && finalMegapixel <= 25) {
          tempCreditCost = 15
        } else if (finalMegapixel > 25 && finalMegapixel <= 100) {
          tempCreditCost = 60
        } else if (finalMegapixel > 100 && finalMegapixel <= 178) {
          tempCreditCost = 150
        }
      } else if (multiplier > 2 && multiplier <= 4) {
        if (finalMegapixel <= "4") {
          tempCreditCost = 10
        } else if (finalMegapixel > 4 && finalMegapixel <= 9) {
          tempCreditCost = 15
        } else if (finalMegapixel > 9 && finalMegapixel <= 25) {
          tempCreditCost = 20
        } else if (finalMegapixel > 25 && finalMegapixel <= 49) {
          tempCreditCost = 70
        } else if (finalMegapixel > 49 && finalMegapixel <= 100) {
          tempCreditCost = 75
        } else if (finalMegapixel > 100 && finalMegapixel <= 178) {
          tempCreditCost = 165
        }
      } else if (multiplier > "4" && multiplier <= "8") {
        if (finalMegapixel <= 4) {
          tempCreditCost = 15
        } else if (finalMegapixel > 4 && finalMegapixel <= 12) {
          tempCreditCost = 20
        } else if (finalMegapixel > 12 && finalMegapixel <= 20) {
          tempCreditCost = 25
        } else if (finalMegapixel > 20 && finalMegapixel <= 25) {
          tempCreditCost = 30
        } else if (finalMegapixel > 25 && finalMegapixel <= 100) {
          tempCreditCost = 80
        } else if (finalMegapixel > 100 && finalMegapixel <= 178) {
          tempCreditCost = 170
        }
      } else if (multiplier > 8 && multiplier <= 16) {
        if (finalMegapixel <= 4) {
          tempCreditCost = 20
        } else if (finalMegapixel > 4 && finalMegapixel <= 12) {
          tempCreditCost = 25
        } else if (finalMegapixel > 12 && finalMegapixel <= 16) {
          tempCreditCost = 30
        } else if (finalMegapixel > 16 && finalMegapixel <= 25) {
          tempCreditCost = 35
        } else if (finalMegapixel > 25 && finalMegapixel <= 49) {
          tempCreditCost = 80
        } else if (finalMegapixel > 49 && finalMegapixel <= 100) {
          tempCreditCost = 90
        } else if (finalMegapixel > 100 && finalMegapixel <= 178) {
          tempCreditCost = 180
        }
      } else {
        telegram("bug in credit cost calculation!, case not found!")
      }

      if (isPattern && tempCreditCost) {
        tempCreditCost = tempCreditCost * 4
      }

      setCreditCost(tempCreditCost)
    }
  }, [finalMegapixel, multiplier, isPattern])

  const setOptions = (
    optionCreativity,
    optionMultiplier,
    optionPrompt,
    optionDynamic,
    optionResemblance
  ) => {
    setCreativity(optionCreativity)
    setDynamic(optionDynamic)
    setResemblance(optionResemblance)
    setMultiplier(optionMultiplier)
    setPrompt(optionPrompt)
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    setError(null)
  }, [multiplier])

  const handleFileChange = (event) => {
    setError(null)
    const file = event.target.files[0]
    const fileUrl = URL.createObjectURL(file)
    const img = new Image()

    img.onload = () => {
      setSelectedFile({
        file: file,
        width: img.width,
        height: img.height,
        fileUrl: fileUrl,
      })
    }
    img.src = fileUrl
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDrop = (e) => {
    e.preventDefault()

    const files = e.dataTransfer.files

    if (files.length > 0) {
      const file = files[0]
      const fileUrl = URL.createObjectURL(file)
      const img = new Image()

      img.onload = () => {
        setSelectedFile({
          file: file,
          width: img.width,
          height: img.height,
          fileUrl: fileUrl,
        })
      }

      img.src = fileUrl
    }
  }

  const createImageDocument = async () => {
    setError(null)
    if (!user) {
      telegram("Create.js, createImageDocument, user not found!, user: " + user)
      setError("Register to get tokens.")
      return
    }

    if (user.balance <= 0 || user.balance < creditCost) {
      setError("Not enough tokens!")
      history.push("/pricing")
      return
    }

    if (!selectedFile.file) {
      setError("No image selected!")
      return
    }

    if (finalMegapixel > 178) {
      setError(
        "Final resolution too large! Max. upscale resolution is 178 Megapixels."
      )
      return
    }

    const downloadURL = await uploadToR2(selectedFile.file, true)
    const credits = user.balance - creditCost
    try {
      await firestore.collection("users").doc(user.id).update({
        balance: credits,
      })
    } catch (error) {
      telegram("Create, createImageDocument, error: " + error.message)
      setError("Error while updating tokens!")
      return null
    }

    const imageData = {
      userId: user.id,
      createdAt: serverTimestamp(),
      status: "created",
      mode: "upscale",
      inputImage: downloadURL,
      creativity: creativity,
      resemblance: resemblance,
      dynamic: dynamic,
      segmentation: segmentation,
      multiplier: multiplier,
      style: style,
      prompt: prompt,
      creditCost: creditCost,
      width: selectedFile.width,
      height: selectedFile.height,
      stage: stage,
      isPattern: isPattern,
    }

    try {
      const imagesCollectionRef = firestore
        .collection("users")
        .doc(user.id)
        .collection("images")

      const docRef = await imagesCollectionRef.add(imageData)

      return docRef.id
    } catch (error) {
      telegram("Create.js, createImageDocument, error: " + error.message)
      return null
    }
  }

  useEffect(() => {
    setCreateImageFunction(
      () => () =>
        createImageDocument(
          user,
          selectedFile,
          finalMegapixel,
          creditCost,
          history,
          creativity,
          resemblance,
          dynamic,
          segmentation,
          multiplier,
          style,
          prompt,
          stage,
          isPattern,
          setError
        )
    )
  }, [
    user,
    selectedFile,
    finalMegapixel,
    creditCost,
    history,
    creativity,
    resemblance,
    dynamic,
    segmentation,
    multiplier,
    style,
    prompt,
    stage,
    isPattern,
    setError,
    setCreateImageFunction,
  ])

  const handleDeleteClick = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setSelectedFile({ file: null })
  }

  return (
    <div className="w-full pl-3 pr-1 py-2 pb-3 relative md:h-[calc(100vh-194px)] md:overflow-y-auto md:max-w-xs md:pr-3 border-neutral-700">
      <div
        className="file-uploader"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      ></div>

      {/* {user && user.email === "genaiconsult@gmail.com" && (
        <div>
          <div className="flex items-center">
            <p className="font-bold my-2 mr-2 mt-2">Stage (Admin)</p>
          </div>
          <select
            className="bg-neutral-800 w-full border border-neutral-700 text-white text-sm rounded-lg block p-2.5"
            value={stage}
            onChange={(event) => setStage(event.target.value)}
          >
            <option value="live">Live</option>
            <option value="staging">Staging</option>
          </select>
        </div>
      )} */}
      <p className="font-bold my-2">Input image</p>
      <div
        className={
          selectedFile.file
            ? "w-full max-h-64 relative"
            : "flex flex-col items-center justify-center w-full h-32 border border-neutral-700 border-dashed rounded-lg cursor-pointer bg-neutral-800 hover:bg-neutral-700"
        }
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <label htmlFor="dropzone-file" className="w-full h-full">
          {selectedFile.file ? (
            <div className="relative">
              <img
                className="max-h-64 rounded-lg cursor-pointer bg-neutral-800 hover:bg-gray-100"
                src={selectedFile.fileUrl}
                alt="preview"
              />
              <div
                className="absolute text-xs px-2 left-1 top-1 w-14 bg-neutral-800/70 p-1 rounded-lg font-bold cursor-pointer hover:bg-opacity-80 hover:text-black"
                onClick={handleDeleteClick}
              >
                Delete
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <>
                <svg
                  className="w-8 h-8 mb-4 text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>

                <p className="mb-2 px-2 text-sm text-white text-center">
                  Click to upload or drop your image
                </p>
              </>
            </div>
          )}
        </label>
        <input
          type="file"
          id="dropzone-file"
          className="hidden"
          onChange={handleFileChange}
        />
      </div>

      <p className="font-bold mt-6 mb-2">Prompt (optional)</p>
      <textarea
        id="prompt"
        type="text"
        rows="3"
        className="bg-neutral-800 w-full border border-neutral-700 text-white text-sm rounded-lg block p-2.5"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
      />

      <div>
        <div>
          <div className="flex items-center mt-6">
            <p className="font-bold my-2 mr-2">Creativity</p>
            <div
              className="cursor-pointer"
              onMouseEnter={() => setCreativityTooltip(true)}
              onMouseLeave={() => setCreativityTooltip(false)}
            >
              <IconInfo />
            </div>
          </div>
          {creativityTooltip && (
            <div className="absolute z-9 w-4/5 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-700 rounded-lg shadow-sm">
              More freedom for the AI to hallucinate extra details. If too high
              it hallucinate too much, try increase resemblance to fix. If too
              low the output doesnt improve enough.
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
          )}

          <div className="grid items-center">
            <input
              className="w-full h-2 bg-gray-200 rounded-lg cursor-pointer"
              type="range"
              min="-10"
              max="10"
              step="1"
              value={creativity}
              onChange={(event) =>
                setCreativity(parseFloat(event.target.value))
              }
            />
            <span className="justify-self-end">{creativity}</span>
          </div>
        </div>
        <div>
          <div className="flex items-center">
            <p className="font-bold my-2 mr-2">Resemblance</p>
            <div
              className="cursor-pointer"
              onMouseEnter={() => setResemblanceTooltip(true)}
              onMouseLeave={() => setResemblanceTooltip(false)}
            >
              <IconInfo />
            </div>
          </div>
          {resemblanceTooltip && (
            <div className="absolute z-10 w-52 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-700 rounded-lg shadow-sm">
              Increase to stick to your input image.
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
          )}
          <div className="grid items-center">
            <input
              className="w-full h-2 bg-gray-200 rounded-lg cursor-pointer"
              type="range"
              min="-10"
              max="10"
              step="1"
              value={resemblance}
              onChange={(event) =>
                setResemblance(parseFloat(event.target.value))
              }
            />
            <span className="justify-self-end">{resemblance}</span>
          </div>
        </div>
        <div>
          <div className="flex items-center">
            <p className="font-bold my-2 mr-2 mt-2">Dynamic</p>
            <div
              className="cursor-pointer"
              onMouseEnter={() => setDynamicTooltip(true)}
              onMouseLeave={() => setDynamicTooltip(false)}
            >
              <IconInfo />
            </div>
          </div>
          {dynamicToolTip && (
            <div className="absolute z-10 w-52 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-700 rounded-lg shadow-sm">
              Increase dynamic to get stronger details. If too high the output
              gets dirty and pixelated.
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
          )}
          <div className="grid items-center">
            <input
              className="w-full h-2 bg-gray-200 rounded-lg cursor-pointer"
              type="range"
              min="-10"
              max="10"
              step="1"
              value={dynamic}
              onChange={(event) => setDynamic(parseFloat(event.target.value))}
            />
            <span className="justify-self-end">{dynamic}</span>
          </div>
        </div>
        <div>
          <div className="flex items-center">
            <p className="font-bold my-2 mr-2 mt-2">Fractality</p>
            <div
              className="cursor-pointer"
              onMouseEnter={() => setSegmentationTooltip(true)}
              onMouseLeave={() => setSegmentationTooltip(false)}
            >
              <IconInfo />
            </div>
          </div>

          {segmentationTooltip && (
            <div className="absolute z-10 w-52 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-700 rounded-lg shadow-sm">
              Increase to get more small details. If too high you get artifacts.
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
          )}

          <div className="grid items-center">
            <input
              className="w-full h-2 bg-gray-200 rounded-lg cursor-pointer"
              type="range"
              min="-10"
              max="10"
              step="1"
              value={segmentation}
              onChange={(event) =>
                setSegmentation(parseFloat(event.target.value))
              }
            />
            <span className="justify-self-end">{segmentation}</span>
          </div>
        </div>
        <div>
          <div className="flex items-center">
            <p className="font-bold my-2 mr-2 mt-2">Style</p>
            <div
              className="cursor-pointer"
              onMouseEnter={() => setStyleTooltip(true)}
              onMouseLeave={() => setStyleTooltip(false)}
            >
              <IconInfo />
            </div>
          </div>
          {styleTooltip && (
            <div className="absolute z-10 w-52 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-700 rounded-lg shadow-sm">
              The style of your input image. If you'd like to transform the
              style, go to the Stylize tab instead.
              <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
          )}
          <select
            className="bg-neutral-800 w-full border border-neutral-700 text-white text-sm rounded-lg block p-2.5"
            value={style}
            onChange={(event) => setStyle(event.target.value)}
          >
            <option value="default">Default</option>
            <option value="portrait">Portrait</option>
            <option value="anime">Anime</option>
          </select>
        </div>
        <div className="mt-4">
          <div>
            <div className="flex items-center">
              <p className="font-bold my-2 mr-2 mt-2">Scale factor</p>
            </div>
            <select
              className="bg-neutral-800 w-full border border-neutral-700 text-white text-sm rounded-lg block p-2.5"
              value={multiplier}
              onChange={(event) => setMultiplier(event.target.value)}
            >
              <option value="2">x2</option>
              <option value="4">x4</option>
              <option value="6">x6</option>
              <option value="8">x8</option>
              <option value="10">x10</option>
              <option value="12">x12</option>
              <option value="14">x14</option>
              <option value="16">x16</option>
            </select>
          </div>
        </div>
        <div className="mt-4">
          <div>
            <div className="flex items-center">
              <p className="font-bold my-2 mr-2 mt-2">Pattern</p>
              <div
                className="cursor-pointer"
                onMouseEnter={() => setPatternToolTip(true)}
                onMouseLeave={() => setPatternToolTip(false)}
              >
                <IconInfo />
              </div>
            </div>
            {patternToolTip && (
              <div className="absolute z-10 w-52 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-700 rounded-lg shadow-sm">
                For seamless, tileable image patterns when you want to maintain
                their integrity.
                <div className="tooltip-arrow" data-popper-arrow></div>
              </div>
            )}
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={isPattern}
                onChange={handlePatternChange}
              />

              <div className="relative w-11 h-6 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-800 rounded-full peer bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all border-gray-600 peer-checked:bg-blue-600"></div>
            </label>
          </div>
        </div>
      </div>

      {selectedFile.file && (
        <div className="flex flex-col items-center justify-center my-4 py-1 bg-neutral-800 border border-neutral-700 rounded-lg text-sm text-neutral-400">
          <div className="text-center">
            <p>
              Final Resolution: {selectedFile.width * multiplier} x{" "}
              {selectedFile.height * multiplier}
            </p>
            {creditCost ? (
              <p>Will cost {creditCost} tokens</p>
            ) : (
              <p className="text-red-800">
                Exceeds maximum of 13,000 x 13,000 pixel
              </p>
            )}
          </div>
        </div>
      )}

      {error && (
        <div
          className="flex items-center p-4 mb-4 text-sm border rounded-lg bg-gray-800 text-red-400 border-red-800"
          role="alert"
        >
          <div> {error}</div>
        </div>
      )}
    </div>
  )
}
