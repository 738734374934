import { Link } from "react-router-dom"
import { ComparisonSlider } from "../../components/ComparisonSlider"
import { translations } from "../translations"
import {
  IconProductHunt,
  IconReddit,
  IconYoutube,
  IconInteriorAI,
} from "../../components/Icons"

export default function Hero({ keyword, language = "en" }) {
  const t = translations[language] || translations["en"]

  const isAnime = keyword === "anime-image-upscaling"
  const title = isAnime ? t.animeTitle : t.title
  const description = isAnime ? t.animeDescription : t.description
  const buttonText = isAnime ? t.animeButtonText : t.buttonText

  const comparisonImages = isAnime
    ? {
        inputImage:
          "https://r2.clarityai.co/inputs/9549593d-e8e5-4d00-9e10-3766744b491f-spirit_small.jpg",
        outputImage:
          "https://r2.clarityai.co/outputs/mzy66feAaaQ5fdkZzdrobsXM9W63-XmIYK4err0BxYqV1srYQ.jpg",
        altOutput: t.animeAltOutput,
        altInput: t.animeAltInput,
      }
    : {
        outputImage: "https://r2.clarityai.co/inputs/13_after.webp",
        inputImage: "https://r2.clarityai.co/inputs/13_before.webp",
        altOutput: t.altOutput,
        altInput: t.altInput,
      }

  return (
    <div className="w-full flex flex-col items-center justify-center lg:mt-20 bg-bg-color text-white">
      <div className="w-11/12 2xl:w-10/12 flex flex-col md:gap-16 md:flex-row">
        <div className="flex flex-col justify-center mt-10 lg:mt-0 ">
          <h1 className="font-bold text-white text-4xl sm:text-6xl">
            {title[0]}{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400">
              {title[1]}
            </span>
          </h1>
          <div className="mb-10">
            <h2 className="mb-6 text-md sm:text-lg mt-5 font-medium text-white">
              {description}
            </h2>
            <Link to="/dashboard">
              <button
                type="submit"
                className="w-52 h-12 self-center rounded-lg font-bold text-white bg-gradient-to-r via-blue-500  to-emerald-600 from-sky-400 hover:from-black hover:border hover:border-white hover:bg-black hover:text-white background-animate"
              >
                {buttonText}
              </button>
            </Link>
            <span className="flex text-sm mt-2">
              ✨ <span className="font-bold">{t.millionUpscales}&nbsp;</span>
              {t.upscales}
            </span>
          </div>
        </div>

        <div className="mt-12 lg:mt-0 w-full h-full">
          <ComparisonSlider image={comparisonImages} />
        </div>
      </div>

      <div className="w-11/12 2xl:w-10/12 flex flex-col text-center">
        <div className="flex flex-wrap items-center gap-1 justify-center content-center mt-12 md:mt-20 text-gray-500">
          <span className="font-semibold text-sm mr-2">{t.featuredOn}</span>
          <div className="mr-5">
            <IconYoutube />
          </div>
          <div className="mr-5">
            <IconProductHunt />
          </div>
          <div className="flex items-center mr-5">
            <svg
              className="w-8 md:w-9 fill-base-content saturate-0 contrast-50 opacity-80"
              viewBox="0 0 252 252"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_177_29)">
                <path
                  d="M126 250.793C195.036 250.793 251 194.875 251 125.897C251 56.9181 195.036 1 126 1C56.9644 1 1 56.9181 1 125.897C1 194.875 56.9644 250.793 126 250.793Z"
                  fill="black"
                  stroke="white"
                  strokeMiterlimit="10"
                ></path>
                <path
                  d="M48.9999 53.5352L108.748 133.357L48.6233 198.256H62.1561L114.797 141.435L157.327 198.256H203.377L140.265 113.945L196.23 53.5352H182.697L134.219 105.865L95.0494 53.5352H48.9999ZM68.9004 63.4941H90.0554L183.474 188.297H162.319L68.9004 63.4941Z"
                  fill="white"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_177_29">
                  <rect width="252" height="252" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
            <span className="ml-1">X/Twitter</span>
          </div>

          <div className="flex items-center mr-5">
            <div className="flex items-center mr-1 justify-center w-8 h-8 bg-gray-500 rounded-full">
              <IconInteriorAI />
            </div>

            <span className="font-extrabold">INTERIOR AI</span>
          </div>

          <div className="">
            <IconReddit />
          </div>
        </div>
      </div>

      {/* Entfernen Sie die Debug-Ausgabe */}
    </div>
  )
}
