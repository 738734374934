import { Link } from "react-router-dom"
import { translations } from "../translations"

export default function CalltoAction({ keyword, language = "en" }) {
  const isAnime = keyword === "anime-image-upscaling"
  const t = translations[language] || translations["en"]

  const title = isAnime ? t.callToAction.animeTitle : t.callToAction.title
  const description = isAnime
    ? t.callToAction.animeDescription
    : t.callToAction.description
  const buttonText = isAnime
    ? t.callToAction.animeButtonText
    : t.callToAction.buttonText

  return (
    <div className="my-12 w-full flex flex-col items-center">
      <div className="w-11/12 2xl:w-10/12 flex rounded-2xl mt-12 bg-neutral-800">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6 ">
          <div className="mx-auto max-w-screen-sm text-center">
            <h2 className="mb-4 text-4xl font-extrabold text-white">{title}</h2>

            <p className="mb-6 font-light text-white md:text-lg">
              {description}
            </p>
            <Link to="/dashboard">
              <button
                type="submit"
                className="w-44 h-12 flex-none self-center rounded-lg font-bold text-white bg-gradient-to-r via-blue-500  to-emerald-600 from-sky-400 hover:from-black hover:border hover:border-white hover:bg-black hover:text-white background-animate"
              >
                <div className="flex flex-row place-content-center">
                  <span>{buttonText}</span>
                </div>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
