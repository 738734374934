export async function handleGoogleLogin(signupWithGoogle, history, onClose) {
  const result = await signupWithGoogle()
  if (result.state === "new user" || result.state === "existing user") {
    if (result.group === "A" || result.group === "B") {
      history.push("/pricing")
    } else if (result.group === "C" || result.group === undefined) {
      history.push("/dashboard")
    }
  } else {
    console.error("Login error:", result.error)
  }
  if (onClose) {
    onClose()
  }
}
