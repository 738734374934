import React, { useState, useEffect, useRef, useCallback } from "react"
import { useAuth } from "../../context/AuthContext"
import { ComparisonSlider } from "../../components/ComparisonSlider"
import LoadingImage from "../../components/LoadingImage"
import { Image } from "../../components/Image"

export default function Recent({
  setOptions,
  showMetaAdmin = false,
  customImages = null,
}) {
  const { images: contextImages } = useAuth()
  const [images, setImages] = useState([])
  const [displayCount, setDisplayCount] = useState(2)
  const observerRef = useRef(null)

  useEffect(() => {
    if (customImages) {
      setImages(customImages)
    } else {
      setImages(contextImages)
    }
  }, [customImages, contextImages])

  const lastImageRef = useCallback((node) => {
    if (observerRef.current) observerRef.current.disconnect()
    observerRef.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setDisplayCount((prev) => prev + 4)
      }
    })
    if (node) observerRef.current.observe(node)
  }, [])

  const dummyImages = [
    {
      id: "dummy1",
      status: "done",
      inputImage: "/images/14_before.jpg",
      outputImage: "/images/14_after.jpg",
      altOutput: "Upscaled and enhanced image with AI of a jungle",
      altInput: "Original low-resolution AI image of a jungle",
    },
    {
      id: "dummy2",
      status: "done",
      inputImage:
        "https://r2.clarityai.co/inputs/e364851c-87d3-4c5d-bfb2-7f119926b88e-candidate_b_small_screenshot.jpg",
      outputImage:
        "https://replicate.delivery/pbxt/0JiM2h2KWeViAa85CIKTjia0SJPVrCRgjqKP8byHS4GYzwbJA/1337-e4ce447a-19b5-11ef-82df-ea6d108d94ef.jpg",
      altOutput: "Upscaled and enhanced image with AI of a cat",
      altInput: "Original low-resolution AI image of a cat",
    },
  ]

  const displayImages = (
    images && images.length > 0 ? images : dummyImages
  ).filter((image) => !image.deleted)

  return (
    <div className="h-full">
      {displayImages && (
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 pb-4">
          {displayImages.slice(0, displayCount).map((image, index) => {
            if (index === displayCount - 1) {
              return (
                <div
                  className="relative group"
                  key={image.id}
                  ref={lastImageRef}
                >
                  {image.status === "done" ? (
                    <ComparisonSlider
                      image={image}
                      showMeta={true}
                      showControls={true}
                      setOptions={setOptions}
                      showMetaAdmin={showMetaAdmin}
                      scrollZoomEnabled={true}
                    />
                  ) : (
                    (image.status === "processing" ||
                      image.status === "created") && (
                      <LoadingImage key={image.id} image={image} />
                    )
                  )}
                </div>
              )
            } else {
              return (
                <div className="relative group" key={image.id}>
                  {image.status === "done" ? (
                    image.mode === "create" ? (
                      <Image image={image} />
                    ) : (
                      <ComparisonSlider
                        image={image}
                        showMeta={true}
                        showControls={true}
                        setOptions={setOptions}
                        showMetaAdmin={showMetaAdmin}
                        scrollZoomEnabled={true}
                      />
                    )
                  ) : (
                    (image.status === "processing" ||
                      image.status === "created") && (
                      <LoadingImage key={image.id} image={image} />
                    )
                  )}
                </div>
              )
            }
          })}
        </div>
      )}
    </div>
  )
}
