import { useState, useEffect } from "react"

// Angepasste formatDuration Funktion mit einer Dezimalstelle für Sekunden unter 60s
function formatDurationWithDecimal(seconds) {
  if (seconds < 60) {
    return `${seconds.toFixed(1)}s`
  }
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = Math.floor(seconds % 60)
  return remainingSeconds > 0
    ? `${minutes}m ${remainingSeconds}s`
    : `${minutes}m`
}

function LoadingImage({ prompt, image }) {
  const [timeSinceCreation, setTimeSinceCreation] = useState(null)

  useEffect(() => {
    if (!image.createdAt || !image.createdAt.toDate) return

    const startTime = image.createdAt.toDate().getTime()

    const interval = setInterval(() => {
      const now = new Date().getTime()
      const elapsed = (now - startTime) / 1000
      setTimeSinceCreation(elapsed)
    }, 100)

    return () => clearInterval(interval)
  }, [image.createdAt])

  return (
    <div className="relative w-full rounded-lg overflow-hidden">
      <div className="relative flex items-center justify-center">
        {image.inputImage ? (
          <img
            className="w-full h-auto filter blur-sm"
            src={image.inputImage}
            alt="Input"
          />
        ) : (
          <div className="w-full h-64 bg-gradient-to-br from-blue-700 to-purple-800"></div>
        )}

        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-40">
          {/* Ladekreis */}
          <div className="w-16 h-16 border-4 border-white border-t-transparent rounded-full animate-spin mb-4"></div>
          {/* Zeit seit Bilderstellung */}
          {timeSinceCreation !== null && (
            <p className="text-white font-bold mb-4">
              {formatDurationWithDecimal(timeSinceCreation)}
            </p>
          )}
          {/* Bestehendes SVG-Icon */}
          <svg
            className="w-8 h-8 text-white animate-spin"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            {/* ... SVG-Pfade bleiben unverändert ... */}
          </svg>
        </div>
      </div>
      <p className="absolute text-sm text-white rounded-br-lg rounded-tl-lg top-0 left-0 px-2 bg-black/50">
        {prompt}
      </p>
    </div>
  )
}

export default LoadingImage
