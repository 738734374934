export const translations = {
  en: {
    title: ["Upscale and enhance your images with", "AI Magic"],
    description:
      "Clarity AI is a high-resolution upscaler that can enhance images and add details. You can decide how much detail you want the AI to add.",
    buttonText: "Start upscaling now →",
    animeTitle: ["Upscale and enhance your anime images with", "AI Magic"],
    animeDescription:
      "Clarity AI is a high-resolution upscaler for anime and manga images. It enhances character details and improves image quality while preserving the unique art style.",
    animeButtonText: "Upscale Anime Now →",
    millionUpscales: "4.2+ Million",
    upscales: "upscales",
    features: {
      title: "Features",
      upscale: {
        title: "Upscale",
        description:
          "Upscale your images from 64px minimum to 13,000px maximum (176 megapixels). Clarity AI is the highest-resolution AI upscaler available.",
      },
      enhance: {
        title: "Enhance",
        description:
          "Enhance your images by adding details, removing noise or blur, and fixing artifacts in AI images. Additionally, you can style transfer your images.",
      },
      api: {
        title: "API",
        description:
          "Use the API to integrate Clarity AI into your applications. Clarity also offers a ComfyUI plugin.",
      },
    },
    examples: {
      title: "Unlimited options to enhance your images",
      description:
        "Use the latest AI technology to upscale your images, suitable for landscapes, portraits, illustrations, interior design, and many more.",
      animeTitle: "Unlimited options to enhance your anime images",
      animeDescription:
        "Use the latest AI technology to upscale your anime and manga images, enhancing character details and improving image quality while preserving the unique art style.",
      categories: {
        landscapes: "Upscale Landscapes",
        portraits: "High Resolution Portraits",
        upscaleFrom64: "Upscale from 64 pixels to high resolution",
        animals: "Animal images",
        styleTransfer: "Style Transfer",
        dragonBall: "Enhance Dragon Ball Super Images",
        jujutsuKaisen: "Upscale Jujutsu Kaisen Scenes",
        naruto: "Improve Naruto Artwork",
      },
    },
    faq: {
      title: "Frequently Asked Questions",
      options: [
        {
          question: "How does Clarity AI's upscaling & enhancement work?",
          answer:
            "Using Clarity AI feels like wielding a magic wand! Simply upload any image and Clarity AI will upscale it to a higher resolution, infusing it with as many details as you desire. You'll guide the upscaling with descriptions and controls like 'Creativity', allowing you to dictate the AI's level of detail generation.",
        },
        {
          question: "Is Clarity AI user-friendly for beginners?",
          answer:
            "Clarity AI is crafted to be approachable and easy-to-use for all creators, regardless of experience level. Its intuitive interface, comprehensive tutorials, and supportive community are designed to assist you on your creative path.",
        },
        {
          question: "Who benefits from using Clarity AI's upscaler?",
          answer:
            "Clarity AI caters to a wide range of users from professional photographers and graphic designers to digital artists and enthusiasts seeking enhanced high-resolution images. It's also ideal for AI artists looking to refine AI-generated images, businesses enhancing marketing visuals, and anyone aiming to improve personal photos or create high-quality content.",
        },
        {
          question: "Who developed Clarity AI?",
          answer: "Clarity AI was developed by philz1337x",
        },
        {
          question: "Will images upscaled by Clarity AI contain artifacts?",
          answer:
            "Occasionally, yes. However, artifacts can usually be managed with adjustments to the 'Creativity', 'dynamic', and 'Resemblance' sliders, alongside your descriptive prompts. Artifacts tend to arise primarily when 'Creativity' or 'dynamic' levels are too high, or 'Resemblance' is incorrectly set.",
        },
        {
          question: "Is the payment process secure?",
          answer:
            "Absolutely, our payment processing is 100% secure, managed by Stripe, ensuring that no card details are stored on our end. Stripe's security measures are on par with banking standards.",
        },
        {
          question: "Which payment methods are accepted?",
          answer:
            "We accept various credit or debit cards including VISA, MasterCard, and American Express, along with iDeal, SOFORT, and several others. PayPal and cryptocurrency payments are currently not supported.",
        },
        {
          question: "Can I get a refund?",
          answer:
            "Due to the high costs associated with AI-driven processing, we are unable to offer refunds. The significant GPU processing required for upscaling images incurs substantial costs from our providers, which do not accommodate refunds, making it financially unviable for us to offer refunds.",
        },
        {
          question: "How can I cancel my subscription?",
          answer:
            "You may cancel your subscription at any moment. Simply log in, navigate to 'Billing' at the top of Clarity AI's page, and you'll be directed to Stripe's billing portal where you can manage your subscription, including cancellation.",
        },
        {
          question: "How can I contact Clarity AI's support?",
          answer:
            "You can reach out to me via X (formerly Twitter) at @philz1337x",
        },
      ],
    },
    testimonials: {
      title: "Users are enhancing their images",
      animeTitle: "Users are enhancing their anime images",
    },
    callToAction: {
      title: "Convert your images into real masterpieces with AI",
      animeTitle: "Convert your anime images into real masterpieces",
      description:
        "Start now to get your images with super high resolution and intricate details.",
      animeDescription:
        "Start now to get your anime and manga images with super high resolution and intricate details.",
      buttonText: "Upscale now",
      animeButtonText: "Anime Upscaling",
    },
  },
  de: {
    title: ["Skalieren und verbessern Sie Ihre Bilder mit", "KI-Magie"],
    description:
      "Clarity AI ist ein hochauflösender Upscaler, der Bilder verbessern und Details hinzufügen kann. Sie können entscheiden, wie viele Details die KI hinzufügen soll.",
    buttonText: "Jetzt Hochskalieren →",
    animeTitle: [
      "Skalieren und verbessern Sie Ihre Anime-Bilder mit",
      "KI-Magie",
    ],
    animeDescription:
      "Clarity AI ist ein hochauflösender Upscaler für Anime- und Manga-Bilder. Er verbessert Charakterdetails und die Bildqualität und bewahrt dabei den einzigartigen Kunststil.",
    animeButtonText: "Anime hochskalieren →",
    millionUpscales: "4,2+ Millionen",
    upscales: "Hochskalierungen",
    features: {
      title: "Funktionen",
      upscale: {
        title: "Hochskalieren",
        description:
          "Skalieren Sie Ihre Bilder von minimal 64px auf maximal 13.000px (176 Megapixel) hoch. Clarity AI ist der AI-Upscaler mit der höchsten verfügbaren Auflösung.",
      },
      enhance: {
        title: "Verbessern",
        description:
          "Verbessern Sie Ihre Bilder durch Hinzufügen von Details, Entfernen von Rauschen oder Unschärfe und Beheben von Artefakten in KI-Bildern. Zusätzlich können Sie den Stil Ihrer Bilder übertragen.",
      },
      api: {
        title: "API",
        description:
          "Nutzen Sie die API, um Clarity AI in Ihre Anwendungen zu integrieren. Clarity bietet auch ein ComfyUI-Plugin an.",
      },
    },
    examples: {
      title: "Unbegrenzte Möglichkeiten zur Verbesserung Ihrer Bilder",
      description:
        "Nutzen Sie die neueste KI-Technologie, um Ihre Bilder hochzuskalieren. Geeignet für Landschaften, Porträts, Illustrationen, Innenarchitektur und vieles mehr.",
      animeTitle:
        "Unbegrenzte Möglichkeiten zur Verbesserung Ihrer Anime-Bilder",
      animeDescription:
        "Nutzen Sie die neueste KI-Technologie, um Ihre Anime- und Manga-Bilder hochzuskalieren. Verbessern Sie Charakterdetails und die Bildqualität, während der einzigartige Kunststil erhalten bleibt.",
      categories: {
        landscapes: "Landschaften hochskalieren",
        portraits: "Hochauflösende Porträts",
        upscaleFrom64: "Von 64 Pixeln auf hohe Auflösung skalieren",
        animals: "Tierbilder",
        styleTransfer: "Stilübertragung",
        dragonBall: "Dragon Ball Super Bilder verbessern",
        jujutsuKaisen: "Jujutsu Kaisen Szenen hochskalieren",
        naruto: "Naruto Artwork verbessern",
      },
    },
    faq: {
      title: "Häufig gestellte Fragen",
      options: [
        {
          question:
            "Wie funktioniert das Upscaling und die Verbesserung von Clarity AI?",
          answer:
            "Die Verwendung von Clarity AI fühlt sich an wie das Schwingen eines Zauberstabs! Laden Sie einfach ein beliebiges Bild hoch, und Clarity AI skaliert es auf eine höhere Auflösung hoch und fügt so viele Details hinzu, wie Sie wünschen. Sie steuern das Upscaling mit Beschreibungen und Kontrollen wie 'Kreativität', wodurch Sie den Detailgrad der KI bestimmen können.",
        },
        {
          question: "Ist Clarity AI benutzerfreundlich für Anfänger?",
          answer:
            "Clarity AI ist so konzipiert, dass es für alle Kreativen, unabhängig vom Erfahrungsgrad, zugänglich und einfach zu bedienen ist. Die intuitive Benutzeroberfläche, umfassende Tutorials und die unterstützende Community sollen Ihnen auf Ihrem kreativen Weg helfen.",
        },
        {
          question:
            "Wer profitiert von der Verwendung des Clarity AI Upscalers?",
          answer:
            "Clarity AI richtet sich an ein breites Spektrum von Nutzern, von professionellen Fotografen und Grafikdesignern bis hin zu digitalen Künstlern und Enthusiasten, die hochauflösende Bilder verbessern möchten. Es ist auch ideal für KI-Künstler, die KI-generierte Bilder verfeinern mchten, Unternehmen, die Marketing-Visuals verbessern, und jeden, der persönliche Fotos verbessern oder hochwertige Inhalte erstellen möchte.",
        },
        {
          question: "Wer hat Clarity AI entwickelt?",
          answer: "Clarity AI wurde von philz1337x entwickelt.",
        },
        {
          question:
            "Werden von Clarity AI hochskalierte Bilder Artefakte enthalten?",
          answer:
            "Gelegentlich ja. Artefakte können jedoch in der Regel durch Anpassungen der Regler 'Kreativität', 'Dynamik' und 'Ähnlichkeit' sowie durch Ihre beschreibenden Eingaben gesteuert werden. Artefakte treten hauptsächlich auf, wenn die 'Kreativität' oder 'Dynamik' zu hoch eingestellt sind oder die 'Ähnlichkeit' falsch eingestellt ist.",
        },
        {
          question: "Ist der Zahlungsvorgang sicher?",
          answer:
            "Absolut, unsere Zahlungsabwicklung ist zu 100% sicher und wird von Stripe verwaltet, was sicherstellt, dass keine Kartendaten bei uns gespeichert werden. Die Sicherheitsmaßnahmen von Stripe entsprechen den Bankstandards.",
        },
        {
          question: "Welche Zahlungsmethoden werden akzeptiert?",
          answer:
            "Wir akzeptieren verschiedene Kredit- oder Debitkarten, einschließlich VISA, MasterCard und American Express, sowie iDeal, SOFORT und einige andere. PayPal und Kryptowährungszahlungen werden derzeit nicht unterstützt.",
        },
        {
          question: "Kann ich eine Rückerstattung erhalten?",
          answer:
            "Aufgrund der hohen Kosten, die mit der KI-gesteuerten Verarbeitung verbunden sind, können wir keine Rückerstattungen anbieten. Die erhebliche GPU-Verarbeitung, die für das Hochskalieren von Bildern erforderlich ist, verursacht erhebliche Kosten bei unseren Anbietern, die keine Rückerstattungen zulassen, was es für uns finanziell nicht tragbar macht, Rückerstattungen anzubieten.",
        },
        {
          question: "Wie kann ich mein Abonnement kündigen?",
          answer:
            "Sie können Ihr Abonnement jederzeit kündigen. Melden Sie sich einfach an, navigieren Sie zu 'Abrechnung' oben auf der Clarity AI-Seite, und Sie werden zum Abrechnungsportal von Stripe weitergeleitet, wo Sie Ihr Abonnement verwalten und auch kündigen können.",
        },
        {
          question: "Wie kann ich den Support von Clarity AI kontaktieren?",
          answer:
            "Sie können mich über X (ehemals Twitter) unter @philz1337x erreichen.",
        },
      ],
    },
    testimonials: {
      title: "Benutzer verbessern ihre Bilder",
      animeTitle: "Benutzer verbessern ihre Anime-Bilder",
    },
    callToAction: {
      title: "Verwandeln Sie Ihre Bilder mit KI in echte Meisterwerke",
      animeTitle: "Verwandeln Sie Ihre Anime-Bilder in echte Meisterwerke",
      description:
        "Beginnen Sie jetzt, um Ihre Bilder in super hoher Auflösung und mit feinsten Details zu erhalten.",
      animeDescription:
        "Beginnen Sie jetzt, um Ihre Anime- und Manga-Bilder in super hoher Auflösung und mit feinsten Details zu erhalten.",
      buttonText: "Jetzt hochskalieren",
      animeButtonText: "Anime hochskalieren",
    },
  },
  // ... other languages ...
}
