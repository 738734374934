import { ComparisonSlider } from "../../components/ComparisonSlider"
import { translations } from "../translations"

export default function Examples({ keyword, language = "en" }) {
  const t = translations[language] || translations["en"]
  const isAnime = keyword === "anime-image-upscaling"

  const title = isAnime ? t.examples.animeTitle : t.examples.title
  const description = isAnime
    ? t.examples.animeDescription
    : t.examples.description

  const examples = isAnime
    ? [
        {
          title: t.examples.categories.dragonBall,
          images: [
            {
              inputImage:
                "https://r2.clarityai.co/inputs/aec57541-bb1c-4431-8a0d-1da66768b183-DragonBallSuper-TapAndFind-Index.jpg",
              outputImage:
                "https://r2.clarityai.co/outputs/mzy66feAaaQ5fdkZzdrobsXM9W63-NhG6tpP5dICjtA0FcY9y.jpg",
              altInput: "Original low-resolution Dragon Ball Super image",
              altOutput: "Upscaled and enhanced Dragon Ball Super image",
            },
          ],
        },
        {
          title: t.examples.categories.jujutsuKaisen,
          images: [
            {
              inputImage:
                "https://r2.clarityai.co/inputs/5fc804c3-51da-4ced-b10f-d7ff40f7389d-Jujutsu-Kaisen.jpg",
              outputImage:
                "https://r2.clarityai.co/outputs/mzy66feAaaQ5fdkZzdrobsXM9W63-594Ne3UXxhQnxVW3DDgM.jpg",
              altInput: "Original low-resolution Jujutsu Kaisen image",
              altOutput: "Upscaled and enhanced Jujutsu Kaisen image",
            },
          ],
        },
        {
          title: t.examples.categories.naruto,
          images: [
            {
              inputImage:
                "https://r2.clarityai.co/inputs/901a99bc-8c6e-498a-928a-76e3e1dbdb00-naruto-uzumaki-only-kills-one-villain-in-the-entire-series-featured.jpg",
              outputImage:
                "https://r2.clarityai.co/outputs/mzy66feAaaQ5fdkZzdrobsXM9W63-vZ4Vzi7Y13NHwgLHndaB.jpg",
              altInput: "Original low-resolution Naruto image",
              altOutput: "Upscaled and enhanced Naruto image",
            },
          ],
        },
      ]
    : [
        {
          title: t.examples.categories.landscapes,
          images: [
            {
              inputImage:
                "https://r2.clarityai.co/inputs/cdef9338-d80b-40ca-8165-75c1c59d06de-3_before-2.webp",
              outputImage:
                "https://r2.clarityai.co/inputs/mzy66feAaaQ5fdkZzdrobsXM9W63-MpqMhkre7I6n43MAfUns.webp",
              altInput: "Original low-resolution image of a landscape",
              altOutput: "Upscaled image with enhanced details of a landscape",
            },
          ],
        },
        {
          title: t.examples.categories.portraits,
          images: [
            {
              inputImage:
                "https://r2.clarityai.co/inputs/9c6502b5-96d5-427d-b50b-1f563b0b5308-11_before-2.webp",
              outputImage:
                "https://r2.clarityai.co/inputs/mzy66feAaaQ5fdkZzdrobsXM9W63-gy4zvureAsAvP58hvIlq.webp",
              altInput: "Original low-resolution image of a woman",
              altOutput: "Upscaled and enhanced portrait image",
            },
            {
              inputImage:
                "https://r2.clarityai.co/inputs/9726cc1c-5168-4930-af6e-a11efac3cf19-7_before-2.webp",
              outputImage:
                "https://r2.clarityai.co/inputs/mzy66feAaaQ5fdkZzdrobsXM9W63-23rVuuYYOwCcXdnVKeTG.webp",
              altInput: "Original low-resolution image of a portrait",
              altOutput: "Upscaled image with enhanced details of a portrait",
            },
          ],
        },
        {
          title: t.examples.categories.upscaleFrom64,
          images: [
            {
              outputImage:
                "https://r2.clarityai.co/inputs/adda982a-72b4-4f4d-8f6e-1567c534f058-dog.webp",
              inputImage:
                "https://r2.clarityai.co/inputs/46f9dec4-aa01-4e38-828f-13e5415f53e0-dog_low.webp",
              altOutput: "Upscaled and enhanced dog image",
              altInput:
                "Original very low resolution image of a dog with only 64 x 64 pixels",
            },
          ],
        },
        {
          title: t.examples.categories.animals,
          images: [
            {
              outputImage:
                "https://r2.clarityai.co/inputs/mzy66feAaaQ5fdkZzdrobsXM9W63-dnUb31bTVMaGUxGCWM8f.webp",
              inputImage:
                "https://r2.clarityai.co/inputs/b21dbbd4-1d93-4182-92c4-a380803931fd-fox_1024.webp",
              altOutput: "Enhanced AI image of a fox",
              altInput: "AI image of a fox with 1024 x 1024 pixels",
            },
          ],
        },
        {
          title: t.examples.categories.styleTransfer,
          images: [
            {
              outputImage:
                "https://r2.clarityai.co/inputs/f3693bb2-2341-4e20-a439-dd8bf4acefd0-house_style_after.webp",
              inputImage:
                "https://r2.clarityai.co/inputs/7ce139f1-a3e2-4757-9a7e-90d270db2d11-house_style_before.webp",
              altOutput: "Realistic AI image of a house with a different style",
              altInput: "Original drawing of a house",
            },
          ],
        },
      ]

  return (
    <div className="w-full flex flex-col items-center justify-center pt-24 xl:mt-24">
      <div className="w-11/12 2xl:w-10/12 items-center flex flex-col text-center">
        <h2 className="mb-4 text-3xl sm:text-4xl font-extrabold text-white mt-12 md:mt-20">
          {title}
        </h2>
        <p className="text-lg font-normal text-white lg:text-xl mb-20">
          {description}
        </p>

        {examples.map((example, index) => (
          <div key={index} className="w-full mb-20">
            <h3 className="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-sky-400 text-2xl sm:text-3xl font-bold mb-6">
              {example.title}
            </h3>
            <div
              className={`grid grid-cols-1 ${
                example.images.length > 1 ? "md:grid-cols-2" : ""
              } gap-4`}
            >
              {example.images.map((image, imgIndex) => (
                <ComparisonSlider key={imgIndex} image={image} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
