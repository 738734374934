import { TwitterTweetEmbed } from "react-twitter-embed"
import { translations } from "../translations"

export default function Testimonials({ keyword, language = "en" }) {
  const isAnime = keyword === "anime-image-upscaling"
  const t = translations[language] || translations["en"]

  const tweetIds = isAnime
    ? ["1791775157218066520", "1773342568543346738"]
    : [
        "1776729356120797265",
        "1794195254247317733",
        "1777302243563143188",
        "1775265742445072878",
        "1771163670082417009",
        "1791775157218066520",
        "1769929015736074460",
        "1759046455707205758",
      ]

  const title = isAnime ? t.testimonials.animeTitle : t.testimonials.title

  return (
    <div className="w-full flex flex-col items-center justify-center lg:mt-20 bg-bg-color text-white">
      <div className="w-11/12 2xl:w-10/12 flex flex-col md:gap-16 lg:flex-row">
        <div className="flex flex-col items-center w-full">
          <h2 className="mb-4 text-3xl sm:text-4xl font-extrabold text-white mt-28">
            {title}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-8 w-full">
            {tweetIds.map((tweetId) => (
              <div key={tweetId} className="h-auto max-w-full">
                <TwitterTweetEmbed
                  tweetId={tweetId}
                  options={{
                    theme: "dark",
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
