import { FaSearch, FaMagic, FaCog } from "react-icons/fa"
import { translations } from "../translations"

export default function Features({ language = "en" }) {
  const t = translations[language] || translations["en"]

  return (
    <div className="w-full flex flex-col items-center justify-center mt-32 lg:mt-48 bg-bg-color text-white">
      <div className="w-11/12 2xl:w-10/12 flex flex-col md:gap-16 lg:flex-row">
        <div className="flex flex-col items-center w-full">
          <h2 className="text-3xl sm:text-4xl font-extrabold text-white">
            {t.features.title}
          </h2>

          <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
            <div className="space-y-8 lg:grid lg:grid-cols-3 lg:gap-12 lg:space-y-0">
              <FeatureItem
                icon={
                  <FaSearch className="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" />
                }
                title={t.features.upscale.title}
                description={t.features.upscale.description}
              />
              <FeatureItem
                icon={
                  <FaMagic className="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" />
                }
                title={t.features.enhance.title}
                description={t.features.enhance.description}
              />
              <FeatureItem
                icon={
                  <FaCog className="w-5 h-5 text-primary-600 lg:w-6 lg:h-6 dark:text-primary-300" />
                }
                title={t.features.api.title}
                description={t.features.api.description}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function FeatureItem({ icon, title, description }) {
  return (
    <div>
      <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 bg-[#1da1f2]">
        {icon}
      </div>
      <h3 className="mb-2 text-xl font-bold dark:text-white">{title}</h3>
      <p className="text-gray-500 dark:text-gray-400">{description}</p>
    </div>
  )
}
