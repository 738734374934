import * as AWS from "aws-sdk"
import { telegram } from "../utils/telegram"

async function convertToJPG(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = async (event) => {
      const img = new Image()
      img.onload = async () => {
        const canvas = document.createElement("canvas")
        canvas.width = img.width
        canvas.height = img.height
        const ctx = canvas.getContext("2d")
        ctx.drawImage(img, 0, 0)
        try {
          const blob = await new Promise((resolve) => {
            canvas.toBlob(resolve, "image/jpeg", 0.95)
          })
          resolve(blob)
        } catch (err) {
          reject(err)
          telegram("frontend, convertToJPG: " + err.message)
        }
      }
      img.src = event.target.result
    }
    reader.onerror = (error) => {
      reject(error)
      telegram("frontend, convertToJPG: " + error.message)
    }
    reader.readAsDataURL(file)
  })
}

export async function uploadToR2(file, convertToJPGOption = false) {
  const fileInfo = file
    ? {
        name: file.name,
        type: file.type,
        size: file.size,
      }
    : "File is undefined or null"

  const maxRetries = 3
  const retryDelay = 2000 // 2 seconds

  for (let attempt = 1; attempt <= maxRetries; attempt++) {
    try {
      if (!file) {
        throw new Error("File is undefined or null")
      }

      if (!(file instanceof File)) {
        throw new Error(`Invalid file type. Expected File, got ${typeof file}`)
      }

      const accessKeyId = `3736df7c42164f7c799db4603ac8848f`
      const secretAccessKey = `798616681ca25c9d3ab935e957566b7c90ba1d363aebd2765fdf262d5f157861`
      const endpoint =
        "https://d181f16c5eadd7fb49486c2652a0f065.r2.cloudflarestorage.com"
      const bucketName = "clarity"

      const s3 = new AWS.S3({
        region: "auto",
        signatureVersion: "v4",
        credentials: new AWS.Credentials(accessKeyId, secretAccessKey),
        endpoint: new AWS.Endpoint(endpoint),
      })
      const uuid = require("uuid")
      let filename = `inputs/${uuid.v4()}-${file.name}`
      let fileBuffer = file

      if (convertToJPGOption) {
        try {
          fileBuffer = await convertToJPG(file)
        } catch (error) {
          console.error("Error while converting to JPG:", error)
          telegram("convertToJPG: " + error.message)
          throw error
        }
        filename = filename.replace(/\.[^/.]+$/, ".jpg") // Change the file extension to .jpg
      }

      const params = {
        Bucket: bucketName,
        Key: filename,
        Body: fileBuffer,
        ContentType: convertToJPGOption ? "image/jpeg" : file.type,
        ACL: "public-read",
      }

      const uploadStartTime = Date.now()
      await s3.upload(params).promise()
      const uploadDuration = Date.now() - uploadStartTime

      const link = "https://r2.clarityai.co/" + filename

      // Log successful upload
      console.log(
        `File uploaded successfully. Attempt: ${attempt}, Duration: ${uploadDuration}ms`
      )

      return link
    } catch (error) {
      const errorMessage = `uploadToR2 error (Attempt ${attempt}/${maxRetries}): ${
        error.message
      }. File info: ${JSON.stringify(
        fileInfo
      )}. ConvertToJPG: ${convertToJPGOption}. Browser: ${
        navigator.userAgent
      }. Online: ${navigator.onLine}`
      console.error(errorMessage)
      telegram(errorMessage)

      if (attempt === maxRetries) {
        throw error
      } else {
        console.log(`Retrying upload in ${retryDelay / 1000} seconds...`)
        await new Promise((resolve) => setTimeout(resolve, retryDelay))
      }
    }
  }
}
