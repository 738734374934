import React, { useState } from "react"
import { useAuth } from "../../context/AuthContext"
import UpscaleCreative from "./UpscaleCreative"
import UpscaleBasic from "./UpscaleBasic"
import StyleImage from "./StyleImage"
import StyleVideo from "./StyleVideo"
import Pattern from "./Pattern"
import Video2Sound from "./Video2Sound"
import Create from "./Create"
import SubmitButton from "../../components/SubmitButton"

export default function Options({ mode, setShowPopup }) {
  const { user } = useAuth()
  const [isLoading, setisLoading] = useState(false)
  const [createImageFunction, setCreateImageFunction] = useState(null)
  const [creditCost, setCreditCost] = useState(false)
  const [styleMode, setStyleMode] = useState("style-image")
  const [upscaleMode, setUpscaleMode] = useState("creative")

  const validPlans = [
    "pro_19usd",
    "pro_29usd",
    "premium_49usd",
    "premium_74usd",
    "business",
    "admin",
  ]

  const submitButtonClicked = async () => {
    setisLoading(true)
    if (createImageFunction) {
      await createImageFunction()
    }

    setisLoading(false)
  }

  return (
    <div className="md:w-96 w-full h-full md:h-screen-16 md:overflow-y-auto md:max-w-xs md:border-r md:border-l border-neutral-700">
      {user && user.balance <= 100 && validPlans.includes(user.plan) && (
        <div>
          <p className="font-bold mt-6 mb-2">
            Add more tokens to your account with a one-time payment:
          </p>
          <div className="flex justify-center items-center mt-6">
            <stripe-buy-button
              className="align-middle"
              buy-button-id="buy_btn_1PFv9gKhV52MrjY6FMsAivnE"
              customer-email={user?.email}
              publishable-key="pk_live_51OP3HQKhV52MrjY6hU7IUmV9HRYKnp8QBgA41f7E2BzO9yFEJSOQ3QenB8bHApfVoHJFj6iryrnalopslAxriOE20017CS8uxp"
            ></stripe-buy-button>
          </div>
        </div>
      )}
      {mode === "upscale" && (
        <>
          <div className="flex h-10 justify-center mx-3 my-2 py-1 bg-neutral-800 border border-neutral-700 rounded-lg">
            <button
              onClick={() => setUpscaleMode("creative")}
              title="Improve image and add details, but can change the image"
              className={`w-1/2 ml-2 mr-1 transition-colors duration-300 rounded-lg ease-in-out ${
                upscaleMode === "creative"
                  ? "bg-blue-600 text-white"
                  : "text-neutral-300 hover:bg-neutral-700 hover:text-white"
              }`}
            >
              Creative
            </button>
            <button
              onClick={() => setUpscaleMode("basic")}
              title="Enhance sharpness without changing details"
              className={`w-1/2 ml-1 mr-2 transition-colors duration-300 rounded-lg ease-in-out ${
                upscaleMode === "basic"
                  ? "bg-blue-600 text-white"
                  : "text-neutral-300 hover:bg-neutral-700 hover:text-white"
              }`}
            >
              Crystal
            </button>
          </div>
          {upscaleMode === "creative" ? (
            <UpscaleCreative
              setCreateImageFunction={setCreateImageFunction}
              creditCost={creditCost}
              setCreditCost={setCreditCost}
            />
          ) : (
            <UpscaleBasic
              setCreateImageFunction={setCreateImageFunction}
              creditCost={creditCost}
              setCreditCost={setCreditCost}
            />
          )}
        </>
      )}
      {mode === "style-transfer" && (
        <>
          <div className="flex h-10 justify-center mx-3 my-2 py-1 bg-neutral-800 border border-neutral-700 rounded-lg">
            <button
              onClick={() => setStyleMode("style-image")}
              className={`w-1/2 ml-2 mr-1 transition-colors duration-300 rounded-lg ease-in-out ${
                styleMode === "style-image"
                  ? "bg-blue-600 text-white"
                  : "text-neutral-300 hover:bg-neutral-700 hover:text-white"
              }`}
            >
              Image
            </button>
            <button
              onClick={() => setStyleMode("style-video")}
              className={`w-1/2 ml-1 mr-2 transition-colors duration-300 rounded-lg ease-in-out ${
                styleMode === "style-video"
                  ? "bg-blue-600 text-white"
                  : "text-neutral-300 hover:bg-neutral-700 hover:text-white"
              }`}
            >
              Video
            </button>
          </div>
          {styleMode === "style-image" ? (
            <StyleImage
              setCreateImageFunction={setCreateImageFunction}
              creditCost={creditCost}
              setCreditCost={setCreditCost}
            />
          ) : styleMode === "style-video" ? (
            <StyleVideo
              setCreateImageFunction={setCreateImageFunction}
              creditCost={creditCost}
              setCreditCost={setCreditCost}
            />
          ) : null}
        </>
      )}
      {mode === "pattern-create" && (
        <Pattern
          setCreateImageFunction={setCreateImageFunction}
          creditCost={creditCost}
          setCreditCost={setCreditCost}
        />
      )}
      {mode === "video-to-sound" && (
        <Video2Sound
          setCreateImageFunction={setCreateImageFunction}
          creditCost={creditCost}
          setCreditCost={setCreditCost}
        />
      )}
      {mode === "create" && (
        <Create
          setCreateImageFunction={setCreateImageFunction}
          creditCost={creditCost}
          setCreditCost={setCreditCost}
        />
      )}
      <SubmitButton
        isLoading={isLoading}
        user={user}
        submitButtonClicked={submitButtonClicked}
        setShowPopup={setShowPopup}
        mode={mode}
        creditCost={creditCost}
      />
    </div>
  )
}
